.breadCrumb {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}
.breadCrumb > h6 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 20px;
}
.breadCrumb > h6 > a {
  color: black;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  &:hover {
    color: var(--customColor);
  }
}
.contactHouse {
  font-size: 20px;
}
.breadCrumb > h6 > span {
  color: var(--customColor);
}
.breadCrumb > h6 > span > a {
  color: var(--customColor);
}
@media screen and (min-width: 320px) and (max-width: 768px) {
}
