.contactUsHeader {
  /* border: solid red; */
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  position: relative;
  background-color: #f5f8fe;
}
.contactBlob {
  /* border: solid blue; */
  position: absolute;
  right: -200px;
  top: -200px;
  transform: scale(1.4);
}
.contactUsHeader > h2 {
  font-size: 50px;
  position: static;
  z-index: 3;
}
.contactUsWrapper {
  /* border: solid green; */
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.contactUsContent1 {
  /* border: solid brown; */
  width: 30%;
  padding: 10px;
  background-color: white;
  border-radius: 20px;
}
.contactUsContent2 {
  /* border: solid black; */
  width: 50%;
  padding: 5px;
}
/* -------------------ContactUsAddress------------------ */

.contactUsAddress > h6 {
  color: var(--customColor);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  text-align: start;
  width: 100%;
}
.contactUsAddress > h6::before {
  content: "•";
  font-size: 20px;
  color: var(--customColor);
}
.contactUsAddress > h6::after {
  content: "•";
  font-size: 20px;
  color: rgb(0, 174, 255);
}
.contactUsAddress > h3 {
  font-size: 40px;
}
.contactUsAddress > p {
  font-size: 15px;
}
.contactUsAddress > li {
  font-size: 18px;
  list-style: none;
  padding: 15px;
  text-align: start;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}
.contactUsAddress > li > p {
  color: black;
}
.contactUsAddress > li > p > a {
  color: black;
  text-decoration: none;
}
/* ----------------------contactUsForm----------------------- */
.contactUsForm {
  /* border: solid red; */
  padding: 10px;
}
.contactUsForm > h6 {
  color: var(--customColor);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  text-align: start;
  width: 100%;
}
.contactUsForm > h6::before {
  content: "•";
  font-size: 20px;
  color: var(--customColor);
}
.contactUsForm > h6::after {
  content: "•";
  font-size: 20px;
  color: rgb(0, 174, 255);
}
.contactUsForm > h3 {
  font-size: 40px;
}
.contactUsForm > form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}
.firstLayer > input {
  border: none;
  outline: none;
  background-color: white;
  padding: 10px;
  border-radius: 20px;
  margin: 10px;
  gap: 10px;
}
.secondLayer {
  width: 100%;
}
.secondLayer > textarea {
  border: none;
  background-color: white;
  padding: 10px;
  outline: none;
  width: 100%;
  border-radius: 20px;
}
.thirdLayer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.thirdLayer > button {
  border: none;
  padding: 10px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: var(--customColor);
  color: white;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.thirdLayer > button::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 0;
  background: radial-gradient(
    465px at -15.1% -25%,
    rgb(11, 18, 236) 0%,
    rgb(67, 166, 238) 49%,
    rgb(126, 203, 244) 90.2%
  );
  transition: 300ms ease-in-out;
  z-index: -1;
}
.thirdLayer > button:hover::after {
  width: 100%;
}
/* -------------------------contactUsMap--------------------------- */
.contactUsMap {
  /* border: solid red; */
  width: 100%;
}
.contactUsMap > iframe {
  width: 100%;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .contactBlob {
    /* border: solid blue; */
    right: -300px;
    top: -300px;
    transform: scale(1);
  }
  .contactUsWrapper {
    /* border: solid green; */
    flex-direction: column;
  }

  .contactUsContent1 {
    /* border: solid brown; */
    width: 100%;
  }
  .contactUsContent2 {
    /* border: solid black; */
    width: 100%;
  }
  .firstLayer {
    /* border: solid ; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .firstLayer>input{
    /* border: solid ; */
    width: 100%;
  }
  .thirdLayer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 769px) and (max-width: 990px) {
  .contactBlob {
    /* border: solid blue; */
    right: -300px;
    top: -300px;
    transform: scale(1);
  }
  .contactUsWrapper {
    /* border: solid green; */
    flex-direction: column;
  }
  .contactUsContent1 {
    /* border: solid brown; */
    width: 100%;
  }
  .contactUsContent2 {
    /* border: solid black; */
    width: 100%;
  }
  .firstLayer {
    /* border: solid ; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .firstLayer>input{
    /* border: solid ; */
    width: 100%;
  }
  .thirdLayer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
