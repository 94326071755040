.homeService {
  /* border: solid red; */
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.homeServiceHead {
  /* border: solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.homeServiceHead > h6 {
  /* border: solid blue; */
  color: var(--customColor);
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  background-color: var(--customWhite);
  padding: 5px;
}
.homeServiceHead > h6::before {
  content: "•";
  font-size: 20px;
  color: var(--customColor);
}
.homeServiceHead > h6::after {
  content: "•";
  font-size: 20px;
  color: rgb(0, 174, 255);
}

.homeServiceHead > h2 {
  font-size: 40px;
  text-align: center;
}
.homeServiceHead > h2 > span {
  color: var(--customColor);
}
.homeServiceHead > p {
  color: grey;
  font-size: 20px;
}

.homeServiceContent {
  /* border: solid blue; */
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
}
/* ----------------------HomeServiceCard----------------- */
.homeServiceCard {
  /* border: solid green; */
  width: 300px;
  height: 563px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 20px;
  transition: 300ms ease-in-out;
  &:hover {
    box-shadow: 0 0 20px rgba(109, 109, 109, 0.84);
  }
}
.homeServiceCardContent1 {
  /* border: solid red; */
  width: 100%;
  height: 250px;
  overflow: hidden;
  border-radius: 20px;
}
.homeServiceCardContent1 > img {
  transition: 600ms ease-in-out;
}
.homeServiceCard:hover .homeServiceCardContent1 > img {
  transform: scale(1.1);
}
.homeServiceCardContent2 {
  width: 100%;
  text-align: center;
}
.homeServiceCardContent2 > h5 {
  color: var(--customColor);
}
.homeServiceCardContent3 > p {
  text-align: justify;
  color: grey;
}
.homeServiceCardContent4 {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeServiceCardContent4 > a {
  text-decoration: none;
}
.homeServiceCardContent4 > a > button {
  border: none;
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  z-index: 1;
  border-radius: 30px;
  transition: 400ms ease-in-out;
  overflow: hidden;
  background-color: white;
  &:hover {
    color: white;
  }
}
.servArrow {
  color: white;
}
.homeServiceCardContent4 > a > button::after {
  content: "";
  position: absolute;
  background-color: var(--customColor);
  height: 100%;
  width: 50px;
  border-radius: 100%;
  left: 0px;
  z-index: -1;
  transition: 300ms ease-in-out;
}
.homeServiceCardContent4 > a > button:hover::after {
  width: 100%;
  border-radius: 0%;
}
