.lp1ContactUs {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lp1ContactUs-Content {
  /* border: solid blue; */
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lp1ContactUs-Content > img {
  width: 450px;
  height: 450px;
  border-radius: 20px;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .lp1ContactUs {
    /* border: solid red; */
    flex-direction: column;
  }
  .lp1ContactUs-Content {
    /* border: solid blue; */
    width: 100%;
    height: 100%;
  }
  .lp1ContactUs-Content > img {
    width: 100%;
    height: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 990px) {
  .lp1ContactUs {
    /* border: solid red; */
    flex-direction: column;
  }
  .lp1ContactUs-Content {
    /* border: solid blue; */
    width: 100%;
    height: 100%;
  }
  .lp1ContactUs-Content > img {
    width: 300px;
    height: 300px;
  }

}
