.landingPage {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 100px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .landingPage {
    /* border: solid red; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 0px;
  }
}
