.navbarContainer {
  /* border: solid green; */
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 10;
  background-color: white;
  transition: 500ms ease-in-out;
}
.navbarContainer1 {
  /* border: solid red; */
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 15px;
  background-color: transparent;
  z-index: 10;
  transition: 500ms ease-in-out;
}
.navbarContent {
  /* border: solid brown; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 99%;
  height: 100%;
  border-radius: 20px;
  background-color: white;
}

.logoContainer {
  /* border: solid green; */
  width: 15%;
  height: 100%;
  overflow: hidden;
}
.logoContainer > a > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.3);
}
.menuContainer {
  /* border: solid blue; */
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 70%;
  height: 100%;
}
.menuContainerList {
  /* border: solid blue; */
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
}
.menuContainerList > li {
  margin: 10px;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menuContainerList > li > a {
  font-size: 15px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.menuContainerList > li > span {
  font-size: 15px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: default;
}
.menuContainerList a {
  text-decoration: none;
  color: black; /* Default color */
}

.menuContainerList .active {
  color: var(--customColor); /* Color when active */
}

.dropDown {
  /* border: solid red; */
  position: absolute;
  width: 300px;
  background-color: rgb(67, 67, 67);
  right: -100px;
  top: 70px;
  padding: 10px;
  display: none;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  transform: translateY(10px);
  transition: 1s ease-in-out;
}
.dropDown > li {
  position: relative;
  transition: 300ms ease-in-out;
}
.dropDown > li > a {
  color: var(--customColor);
}
.dropDown > li::before {
  content: "";
  position: absolute;
  bottom: 0;
  /* border: 1px solid var(--customColor); */
  opacity: 0;
  transition: 300ms ease-in-out;
  height: 1px;
  width: 0%;
  background-color: var(--customColor);
}
.dropDown > li:hover::before {
  width: 100%;
  opacity: 1;
}
.menuContainerList > li:hover .dropDown {
  display: flex;
  transform: translateY(1px);
}
.innerMenu > li {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.respDropDown {
  /* border: solid red; */
  width: 100%;
  display: none;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}
.respDropDown > li > a {
  /* border: solid blue; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  text-decoration: none;
  color: black;
}
/* ---------------------ResponsiveMenu----------------- */
.respMenuBar {
  /* border: solid red; */
  width: 40px;
  height: 40px;
  margin-left: 150px;
  display: none;
}
.respMenuBar > img {
  width: 100%;
  height: 100%;
}
.respMenu {
  /* border: solid blue; */
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: 1s ease;
  background-color: #fd8743de;
  display: flex;
  overflow: hidden;
}

.innerRespMenu {
  /* border: solid green; */
  position: absolute;
  right: 0;
  width: 90%;
  height: 100%;
  top: 0;
  transform: translateX(100%);
  transition: 1s ease;
  transition-delay: 1s;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
}
.respMenuBar > img:hover .respMenu {
  transform: translateX(0);
}
.closeX {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 30px;
}

.innerMenu {
  /* border: solid red; */
  height: 80%;
  width: 90%;
}
.innerMenu > li {
  /* border: solid; */
  border-bottom: 1px solid grey;
  list-style: none;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}
.innerMenu > li > span {
  text-decoration: none;
  font-size: 20px;
  width: 100%;
  color: var(--customDarkBlue);
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.innerMenu > li > a {
  text-decoration: none;
  font-size: 20px;
  width: 100%;
  color: var(--customDarkBlue);
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.innerMenu li > a > button {
  border: solid white;
  border-radius: 20px;
  background-color: var(--customBlue);
  color: white;
  padding: 10px;
  font-weight: 500;
}
.innerMenu li > a > button:hover {
  background-color: white;
  border: solid var(--customBlue);
  color: var(--customBlue);
}
.innerMenuDrop {
  border: 1px solid grey;
  width: 100%;
  border-radius: 20px;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
.innerMenu .active {
  color: var(--customColor); /* Color when active */
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .navbarContainer1 {
    /* border: solid red; */
    position: sticky;
    top: 0px;
  }
  .menuContainerList {
    display: none;
  }
  .logoContainer {
    /* border: solid green; */
    width: 100px;
  }
  .navbarContent {
    /* border: solid brown; */
    border-radius: 0px;
  }
  .respMenuBar {
    /* border: solid red; */
    display: block;
  }
}
@media screen and (min-width: 768px) and (max-width: 979px) {
  .navbarContainer1 {
    /* border: solid red; */
    position: sticky;
    top: 0px;
  }
  .menuContainerList {
    display: none;
  }
  .logoContainer {
    /* border: solid green; */
    width: 100px;
  }
  .navbarContent {
    /* border: solid brown; */
    border-radius: 0px;
  }
  .respMenuBar {
    /* border: solid red; */
    display: block;
    margin-left: 400px;
  }
}
@media screen and (min-width: 990px) and (max-width: 1200px) {
  .menuContainer {
    /* border: solid blue; */
    width: 80%;
  }
  .menuContainerList > li {
    text-align: center;
  }
}
