.lp1Package {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
}
.lp1PackageHead > h6 {
  color: var(--customColor);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
  width: 100%;
}
.lp1PackageHead > h6::before {
  content: "•";
  font-size: 20px;
  color: var(--customColor);
}
.lp1PackageHead > h6::after {
  content: "•";
  font-size: 20px;
  color: rgb(0, 174, 255);
}
.lp1PackageHead > h2 {
  font-size: 50px;
  width: 100%;
}
.lp1PackageMain {
  /* border: solid blue; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 10px;
}
.lp1PackageCard {
  /* border: solid; */
  border-bottom: solid 5px red;
  width: 400px;
  height: 100%;
  border-radius: 15px;
  box-shadow: 0 0 5px grey;
  position: relative;
  /* background-image: url("./../../../../Img//Lp/lpPackageBg.png");
  background-repeat: no-repeat;
  background-size: cover; */
}

.lp1PackageCardCover {
  /* border: solid red; */
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
  border-radius: 15px;
}
.lp1PackageCardCover-price {
  /* border: solid green; */
  width: 80%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: var(--customColor);
  font-size: 25px;
  color: white;
  font-weight: bold;
  padding: 5px;
}
.lp1PackageCardIco {
  color: white;
}
.lp1PackageCardCover > h4 {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  margin-left: 30px;
  margin-top: 30px;
}
.lp1PackageCardCover > p {
  font-size: 20px;
  width: fit-content;
  height: fit-content;
  margin-left: 30px;
}
.lp1PackageCardCover > ul {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}
.lp1PackageCardCover > ul > li {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-weight: bold;
}
.lp1PackageCardCover > ul > li > img {
  width: 25px;
}
.lpPackageReadMore-Btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lpPackageReadMore-Btn > button {
  padding: 5px;
  color: var(--customColor);
  border-radius: 10px;
  font-size: 18px;
  border: none;
  background-color: white;
  font-weight: 500;
}
.dropMoreContent {
  list-style: none;
  display: none;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}
.dropMoreContent > li {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-weight: bold;
}
.dropMoreContent > li > img {
  width: 25px;
}
.lp1PackageCardCover-btn {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 30px;
  margin-bottom: 30px;
}
.lp1PackageCardCover-btn > a {
  text-decoration: none;
}
.lp1PackageCardCover-btn > a > button {
  border: none;
  padding: 8px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: var(--customColor);
  color: white;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-size: 20px;
  width: 150px;
}
.lp1PackageCardCover-btn > a > button::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 0;
  background: radial-gradient(
    465px at -15.1% -25%,
    rgb(11, 18, 236) 0%,
    rgb(67, 166, 238) 49%,
    rgb(126, 203, 244) 90.2%
  );
  transition: 300ms ease-in-out;
  z-index: -1;
}
.lp1PackageCardCover-btn > a > button:hover::after {
  width: 100%;
}
#localSeoPackage {
  background-color: var(--customColor);
  overflow: hidden;
  border-bottom: solid 5px rgb(25, 159, 249) ;
}
#localSeoPackage > .lp1PackageCardCover {
  color: white;
}
#localSeoPackage > .lp1PackageCardCover > .lp1PackageCardCover-price {
  background: var(--customGradient);
}
#localSeoPackage
  > .lp1PackageCardCover
  > .lp1PackageCardCover-btn
  > a
  > button {
  background-color: black;
}
.lpPackagepopular {
  background: var(--customGradient);
  width: 200px;
  height: 100px;
  padding: 10px;
  position: absolute;
  right: -80px;
  top: -20px;
  transform: rotate(45deg);
}
.lpPackagepopular > h4 {
  color: white;
  margin-left: 40px;
  margin-top: 50px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .lp1Package {
    /* border: solid red; */
    margin-top: 50px;
  }
  .lp1PackageMain {
    /* border: solid blue; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    gap: 40px;
  }
}
