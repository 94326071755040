.homePricingContainer {
  /* border: solid red; */
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.HomePricingHeader > h6 {
  color: var(--customColor);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
  width: 100%;
}
.HomePricingHeader > h6::before {
  content: "•";
  font-size: 20px;
  color: var(--customColor);
}
.HomePricingHeader > h6::after {
  content: "•";
  font-size: 20px;
  color: rgb(0, 174, 255);
}
.HomePricingHeader > h2 {
  font-size: 50px;
  text-align: center;
}

.homePricingMain {
  /* border: solid green; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px;
}
#homePricingMain {
  /* border: solid green; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 50px;
  padding: 10px;
}
/* -----------------BasicPlanCard---------------- */
.basicPlan {
  /* border: solid blue; */
  width: 350px;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  background-color: white;
}
.basicPlan > li {
  list-style: none;
  width: 100%;
  padding: 5px;
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  font-weight: 400;
  color: grey;
}
.basicPlan > li > img {
  width: 20px;
  height: 20px;
}
.basicPlan > h2 > span {
  font-size: 20px;
  color: black;
}
.basicPlan > h2 {
  font-size: 40px;
}
.basicPlan > h3 {
  font-size: 35px;
  text-align: center;
}
.basicBtn > a {
  text-decoration: none;
}
.basicBtn > button {
  border: none;
  padding: 10px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: var(--customColor);
  color: white;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.basicBtn > button::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 0;
  background: radial-gradient(
    465px at -15.1% -25%,
    rgb(11, 18, 236) 0%,
    rgb(67, 166, 238) 49%,
    rgb(126, 203, 244) 90.2%
  );
  transition: 300ms ease-in-out;
  z-index: -1;
}
.basicBtn > button:hover::after {
  width: 100%;
}
/* -----------------------PremiumPlanCard-------------------- */
.premiumPlanCard {
  /* border: solid blue; */
  width: 350px;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  background-color: var(--customColor);
  position: relative;
  overflow: hidden;
  margin-bottom: 200px;
}
.premiumPlanCard > li {
  list-style: none;
  width: 100%;
  padding: 5px;
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  font-weight: 400;
  color: white;
}
.premiumPlanCard > li > img {
  width: 20px;
  height: 20px;
}
.premiumPlanCard > h2 > span {
  font-size: 20px;
}
.premiumPlanCard > h2 {
  font-size: 40px;
  color: white;
}
.premiumPlanCard > h3 {
  font-size: 35px;
  color: white;
  text-align: center;
}
.premiumBtn > a {
  text-decoration: none;
}
.premiumBtn > button {
  border: none;
  padding: 10px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: black;
  color: white;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.premiumBtn > button::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 0;
  background: radial-gradient(
    465px at -15.1% -25%,
    rgb(11, 18, 236) 0%,
    rgb(67, 166, 238) 49%,
    rgb(126, 203, 244) 90.2%
  );
  transition: 300ms ease-in-out;
  z-index: -1;
}
.premiumBtn > button:hover::after {
  width: 100%;
}
.premiumTag {
  /* border: solid red; */
  position: absolute;
  background-color: rgb(0, 123, 255);
  width: 200px;
  height: 100px;
  top: -30px;
  right: -80px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transform: rotate(45deg);
  box-shadow: inset 0 10px 15px rgb(193, 199, 206);
}
.premiumTag > h5 {
  color: white;
  font-size: 20px;
}
.pricingIcon {
  font-size: 30px;
}
/* -----------------EcommercePlanCard--------------- */
#EcommercePlanCard {
  margin-bottom: 300px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .homePricingMain {
    flex-direction: column;
  }
  .premiumPlanCard {
    /* border: solid blue; */
    margin-top: 100px;
  }
  #EcommercePlanCard {
    margin-top: -100px;
  }
}
