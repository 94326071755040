.homeFocused {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.homeFocusedMain {
  /* border: solid blue; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 30px;
}
.homeFocusedCard {
  /* border: solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: var(--customColor);
  width: 250px;
  /* border-radius: 20px; */
}
.homeFocusCardIco{
  color: white;
  font-size: 40px;
}
.homeFocusedCard>h6{
  color: white;
}
@media screen and (min-width:320px) and (max-width:768px) {
  
}