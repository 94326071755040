.lp1Service {
  width: 90%;
  height: 650px;
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.lp1ServiceContent {
  /* border: solid blue; */
  width: 50%;
  height: 100%;
}
.lp1ServiceImgContent {
  /* border: solid green; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
  background: var(--customWhite);
}
.lp1ServiceImgContent > img {
  width: 350px;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
}
.lp1ServiceImgContent > img:nth-child(1) {
  position: absolute;
  left: 70px;
  top: 20px;
}
.lp1ServiceImgContent > img:nth-child(2) {
  position: absolute;
  right: 90px;
  bottom: 50px;
}
.lp1ServiceImgContent > img:nth-child(3) {
  width: 200px;
  height: 200px;
  position: absolute;
  background-color: white;
  padding: 10px;
  border-radius: 100%;
  right: 0;
  top: 100px;
  transition: 1s ease-in-out;
}
.lp1Service:hover .lp1ServiceImgContent > img:nth-child(3) {
  transform: rotate(360deg);
}
/* ---------------------lp1ServiceNavbar--------------------------------- */
.lp1ServiceNavbar {
  /* border: solid yellow; */
  width: 300px;
  height: 250px;
  position: static;
  z-index: 1;
  background-color: var(--customColor);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px;
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}
.lp1ServiceNavbar > li {
  list-style: none;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.lp1ServiceNavbar > li > a {
  color: white;
  font-size: 18px;
  text-decoration: none;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 5px;
  border-radius: 10px;
}
.lp1ServiceNavbar > li > a > img {
  width: 15px;
  height: 15px;
}
.lp1ServiceNavbar .active {
  background-color: #ff6710;
}
.lp1ServiceDisplay {
  /* border: solid brown; */
  width: 100%;
  height: 100%;
}
/* ---------nestedPages1--------------- */
.nestedPages1 {
  /* border: solid pink; */
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}
.nestedPages1 > h2 {
  font-size: 50px;
  font-weight: bold;
  width: 100%;
  text-align: center;
}
.nestedPages1 > p {
  font-size: 18px;
  text-align: justify;
  color: grey;

}
.nestedPages1 > ul {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
}
.nestedPages1 > ul > li {
  list-style: none;
  /* border: solid red; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin: 10px;
}
.nestedPgContent {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.nestedPgContent > h4 {
  font-weight: bold;
}
.lp1ServiceIcon {
  width: 40px;
  height: 40px;
  color: var(--customColor);
}
.nestedPages1 > a {
  text-decoration: none;
}
.nestedPages1 > a > button {
  border: none;
  padding: 10px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: var(--customColor);
  color: white;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 200px;
  font-size: 20px;
}
.nestedPages1 > a > button::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 0;
  background: radial-gradient(
    465px at -15.1% -25%,
    rgb(11, 18, 236) 0%,
    rgb(67, 166, 238) 49%,
    rgb(126, 203, 244) 90.2%
  );
  transition: 300ms ease-in-out;
  z-index: -1;
}
.nestedPages1 > a > button:hover::after {
  width: 100%;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .lp1Service {
    width: 100%;
    height: 100%;
    /* border: solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }
  .lp1ServiceContent {
    /* border: solid blue; */
    width: 100%;
    height: 100%;
  }
  .lp1ServiceContent:nth-child(1) {
    /* border: solid blue; */
    width: 100%;
    height: 800px;
  }

  .lp1ServiceImgContent > img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
  }
  .lp1ServiceImgContent > img:nth-child(1) {
    left: 20px;
    top: 20px;
  }
  .lp1ServiceImgContent > img:nth-child(2) {
    right: 00px;
    bottom: 300px;
  }
  .lp1ServiceImgContent > img:nth-child(3) {
    width: 150px;
    height: 150px;
  }
  .nestedPages1 > h2 {
    font-size: 25px;
    font-weight: bold;
    width: 100%;
    text-align: center;
  }
  .nestedPages1 {
    /* border: solid pink; */
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }
  .nestedPages1 > ul {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin-left: -30px;
  }
  .nestedPages1 > ul > li {
    list-style: none;
    /* border: solid red; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin: 10px;
    width: 100%;
  }
}
