.lp1Footer {
  background-color: #000229;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 50px;
  width: 100%;
  padding: 0px;
}
.lp1Footer > p {
  color: white;
  font-size: 20px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .lp1Footer {
    background-color: #000229;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;
    padding: 10px;
  }
  .lp1Footer > p {
    color: white;
    font-size: 15px;
    width: 100%;
    text-align: center;
    margin-top: 15px;
  }
}
