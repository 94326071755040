.homeAboutUs {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.homeAboutUsContent1 {
  /* border: solid green; */
  width: 50%;
  position: relative;
  overflow: hidden;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeAboutUsContent1 > img {
  width: 450px;
  height: 450px;
  object-fit: cover;
  border-radius: 100%;
  position: static;
  z-index: 1;
}
.blobContain {
  /* border: solid blue; */
  position: absolute;
  width: 100%;
  transform: scale(1.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeAboutUsContent2 {
  /* border: solid brown; */
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.homeAboutUsCardContent {
  /* border: solid black; */
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
/* -------------------------aboutUsHeader---------------- */
.homeAboutUsHeader > h6 {
  color: var(--customColor);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  text-align: start;
  width: 100%;
}
.homeAboutUsHeader > h6::before {
  content: "•";
  font-size: 20px;
  color: var(--customColor);
}
.homeAboutUsHeader > h6::after {
  content: "•";
  font-size: 20px;
  color: rgb(0, 174, 255);
}
.homeAboutUsHeader > h2 {
  font-size: 50px;
}
.homeAboutUsHeader > p {
  color: rgb(102, 102, 102);
  font-weight: bold;
}
/* -------------------------/aboutUsHeader---------------- */
.homeAboutUsContent2 > a {
  text-decoration: none;
}
.homeAboutUsContent2 > a > button {
  border: none;
  padding: 10px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: var(--customColor);
  color: white;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.homeAboutUsContent2 > a > button::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 0;
  background: radial-gradient(
    465px at -15.1% -25%,
    rgb(11, 18, 236) 0%,
    rgb(67, 166, 238) 49%,
    rgb(126, 203, 244) 90.2%
  );
  transition: 300ms ease-in-out;
  z-index: -1;
}
.homeAboutUsContent2 > a > button:hover::after {
  width: 100%;
}
/* ----------------aboutUsCard------------------- */
.homeAboutUsCard {
  /* border: solid blue; */
  width: 45%;
  border-radius: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
}
.homeAboutUsCard > img {
  width: 30px;
  height: 30px;
}
.homeAboutUsCard > h5 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .homeAboutUs {
    /* border: solid red; */
    flex-direction: column;
    gap: 20px;
  }
  .homeAboutUsContent1 {
    /* border: solid green; */
    width: 100%;
  }
  .homeAboutUsContent1 > img {
    width: 100%;
    height: 100%;
    border-radius: 0;

  }
  .blobContain {
    /* border: solid blue; */
    display: none;
    visibility: hidden;
  }
  .homeAboutUsContent2 {
    /* border: solid brown; */
    width: 100%;
    height: 100%;
    padding: 10px;
    gap: 30px;
  }
  .homeAboutUsHeader > h2 {
    font-size: 40px;
  }
  .homeAboutUsCard{
    width: 100%;
  }
}
@media screen and (min-width:768px) and (max-width:979px) {
  .homeAboutUs {
    /* border: solid red; */
    flex-direction: column;
    gap: 20px;
  }
  .homeAboutUsContent1 {
    /* border: solid green; */
    width: 100%;
  }
  .homeAboutUsContent1 > img {
    width: 100%;
    height: 100%;
    border-radius: 0;

  }
  .blobContain {
    /* border: solid blue; */
    display: none;
    visibility: hidden;
  }
  .homeAboutUsContent2 {
    /* border: solid brown; */
    width: 100%;
    height: 100%;
    padding: 10px;
    gap: 30px;
  }
  .homeAboutUsHeader > h2 {
    font-size: 40px;
  }
  .homeAboutUsCard{
    width:fit-content;
    padding: 10px;
  }
}
@media screen and (min-width: 990px) and (max-width: 1200px) {
  .homeAboutUsCard {
    /* border: solid blue; */
    width: 60%;
  }
}