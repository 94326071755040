.homeFaq {
  /* border: solid red; */
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeFaqHead > h6 {
  color: var(--customColor);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  text-align: start;
  width: 100%;
}
.homeFaqHead > h6::before {
  content: "•";
  font-size: 20px;
  color: var(--customColor);
}
.homeFaqHead > h6::after {
  content: "•";
  font-size: 20px;
  color: rgb(0, 174, 255);
}
.homeFaqHead > h2 {
  font-size: 50px;
}
.homeFaqContent1 {
  /* border: solid green; */
  width: 50%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.homeFaqContent2 {
  /* border: solid green; */
  width: 50%;
  height: 100%;
  position: relative;
  /* overflow: hidden; */
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* -------------------homeFaqBlob--------------------------------- */
.homeFaqContent2 > img {
  /* border: solid; */
  width: 450px;
  height: 450px;
  object-fit: cover;
  border-radius: 100%;
  position: static;
  z-index: 1;
}
.homeFaqBlob {
  border: solid blue;
  position: absolute;
  width: fit-content;
  transform: scale(1.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
/* -----------------homeFaqAcc------------------------ */
.homeFaqAcc {
  /* border: solid red; */
  width: 100%;
}
.accordion {
  width: 100%;
}
/* Default style when the accordion is collapsed */
.accordion-button.collapsed {
  background-color: #f8f9fa;
  color: var(--customColor);
}

.homeFaqAcc .accordion-button:not(.collapsed) {
  background-color: var(--customColor) !important;
  color: white !important;
}

/* Optionally, you can add transitions for smooth color change */
.accordion-button {
  transition: background-color 0.3s ease, color 0.3s ease;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .homeFaq {
    /* border: solid red; */
    flex-direction: column-reverse;
  }
  .homeFaqBlob {
    display: none;
  }
  .homeFaqContent2 > img {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  .homeFaqContent1 {
    width: 100%;
  }
  .homeFaqContent2 {
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 980px) {
  .homeFaq {
    /* border: solid red; */
    flex-direction: column-reverse;
  }
  .homeFaqBlob {
    display: none;
  }
  .homeFaqContent2 > img {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  .homeFaqContent1 {
    width: 100%;
  }
  .homeFaqContent2 {
    width: 100%;
  }
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .homeFaq {
    /* border: solid red; */
    flex-direction: column-reverse;
  }
  .homeFaqBlob {
    display: none;
  }
  .homeFaqContent2 > img {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  .homeFaqContent1 {
    width: 100%;
  }
  .homeFaqContent2 {
    width: 100%;
  }
}
@media screen and (min-width: 990px) and (max-width: 1200px) {
  .homeFaq {
    /* border: solid red; */
    flex-direction: column-reverse;
  }
  .homeFaqBlob {
    display: none;
  }
  .homeFaqContent2 > img {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  .homeFaqContent1 {
    width: 100%;
  }
  .homeFaqContent2 {
    width: 100%;
  }
}
