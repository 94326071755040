.lp1Header {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  background-image: url("https://kfkit.rometheme.pro/beevo/wp-content/uploads/sites/79/2024/01/Background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
}

.lp1HeaderContent {
  width: 50%;
  height: 700px;
  /* border: solid green; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.lp1HeaderContent > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.lp1HeaderContent:nth-child(1) {
  padding: 30px;
}
.lp1HeaderContent > h6 {
  color: var(--customColor);
}
.lp1HeaderContent > h2 {
  color: white;
  font-size: 60px;
}
.lp1HeaderContent > p {
  color: rgb(171, 171, 171);
  font-size: 20px;
  text-align: justify;
}
.lp1HeaderContent > button {
  padding: 6px;
  border: none;
  background-color: var(--customColor);
  border-radius: 5px;
}
.lp1HeaderContent > button > a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
  text-decoration: none;
}
.lpHeaderIcon {
  color: white;
}
.lp1Performance-Container {
  /* border: solid red; */
  width: 90%;
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 20px;
}
.lp1Performance {
  /* border: solid green; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
}
.lp1Performance > img {
  width: 200px;
  height: 100%;
  box-shadow: 0 0 10px gray;
}
.lp1PerformanceContent {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 50%;
}
.lp1PerformanceContent > h6 {
  color: var(--customColor);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  text-align: start;
  width: 100%;
}
.lp1PerformanceContent > h6::before {
  content: "•";
  font-size: 20px;
  color: var(--customColor);
}
.lp1PerformanceContent > h6::after {
  content: "•";
  font-size: 20px;
  color: rgb(0, 174, 255);
}
.lp1PerformanceContent > h3 {
  font-size: 30px;
}
.lp1Performance > a {
  text-decoration: none;
}
.lp1Performance > a > button {
  border: none;
  padding: 10px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: var(--customColor);
  color: white;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.lp1Performance > a > button::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 0;
  background: radial-gradient(
    465px at -15.1% -25%,
    rgb(11, 18, 236) 0%,
    rgb(67, 166, 238) 49%,
    rgb(126, 203, 244) 90.2%
  );
  transition: 300ms ease-in-out;
  z-index: -1;
}
.lp1Performance > a > button:hover::after {
  width: 100%;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .lp1Header {
    /* border: solid red; */
    padding: 10px;
    gap: 20px;
  }
  .lp1HeaderContent {
    width: 100%;
    height: 100%;
    /* border: solid green; */
    align-items: center;
  }
  .lp1HeaderContent:nth-child(1) {
    padding: 10px;
  }
  .lp1HeaderContent > h6 {
    color: var(--customColor);
    text-align: center;
  }
  .lp1HeaderContent > h2 {
    color: white;
    font-size: 40px;
    text-align: center;
  }
  .lp1Performance {
    /* border: solid green; */

    flex-direction: column;
  }
  .lp1PerformanceContent {
    width: 100%;
  }
  .lp1PerformanceContent > h6 {
    color: var(--customColor);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-align: center;
    width: 100%;
  }
  .lp1PerformanceContent > h3 {
    font-size: 30px;
    text-align: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 979px) {
  .lp1Header {
    /* border: solid red; */
    padding: 10px;
  }
  .lp1HeaderContent {
    width: 100%;
    height: 100%;
    /* border: solid green; */
  }
  .lp1HeaderContent > h2 {
    color: white;
    width: 100%;
    font-size: 40px;
    text-align: center;
  }
  .lp1Performance {
    /* border: solid green; */

    flex-direction: column;
  }
  .lp1PerformanceContent {
    width: 100%;
  }
  .lp1PerformanceContent > h6 {
    color: var(--customColor);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-align: center;
    width: 100%;
  }
  .lp1PerformanceContent > h3 {
    font-size: 30px;
    text-align: center;
  }
}
