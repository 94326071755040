.googleAdsServiceHead {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}
.googleAdsServiceHead > h6 {
  color: var(--customColor);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
  width: 100%;
}
.googleAdsServiceHead > h6::before {
  content: "•";
  font-size: 20px;
  color: var(--customColor);
}
.googleAdsServiceHead > h6::after {
  content: "•";
  font-size: 20px;
  color: rgb(0, 174, 255);
}
.googleAdsServiceHead > h2 {
  font-size: 50px;
  text-align: center;
}
.googleAdsServiceHead > h2 > span {
  color: var(--customColor);
}
.googleAdsServiceHead > p {
  font-size: 20px;
  text-align: justify;
  color: grey;
  padding: 20px;
  width: 80%;
}
.googleAdsServiceHead > p > a {
  text-decoration: none;
  color: var(--customColor);
  font-size: 20px;
}
.googleAdsServiceHead > ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.googleAdsServiceHead > ul > li {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  font-size: 20px;
  width: 80%;
  color: grey;
}
.googleAds {
  color: var(--customColor);
}
