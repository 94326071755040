.seoMarquee {
    width:100%;
    height: 250px;
    background-color: var(--customLightBlue);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    overflow: hidden;
  }
  .seoMarqueeContent {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  .seoMarqueeContent > h2 {
    font-size: 80px;
    color: var(--customDarkBlue);
  }
  .seoMarqueeContent>span{
    font-size: 40px;
    color: var(--customColor);
  }
  .marqueeIcon {
    font-size: 30px;
    color: var(--customBlue);
  }
  