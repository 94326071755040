.exploreSeoOptimizing {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding: 20px !important;
  padding-left: 50px !important;
}
.exploreSeoOptimizing > h1 {
  color: var(--customColor);
  width: 100%;
  text-align: center;
}
.exploreSeoOptimizing > h2 {
  color: var(--customColor);
  width: 100%;
  text-align: center;
  font-size: 25px;
}
.exploreSeoOptimizing > h3 {
  color: var(--customColor);
  width: 100%;
  text-align: start;
  font-size: 25px;
}
.exploreSeoOptimizing > h6 {
  color: var(--customColor);
  width: 100%;
  text-align: start;
  font-size: 18px;
}
.exploreSeoOptimizing > p {
  width: 100%;
  font-size: 20px;
  text-align: justify;
}
.exploreSeoOptimizing > p > a {
  color: var(--customColor);
  text-decoration: none;
}
.exploreSeoOptimizing > li > h6 {
  color: var(--customColor);
  font-size: 20px;
}
.exploreSeoOptimizing > li > p > a {
  color: var(--customColor);
  text-decoration: none;
}
.exploreSeoOptimizing > li {
  /* border: solid; */
  width: 100%;
  font-size: 20px;
  text-align: start;
  list-style: none;
}
.exploreSeoOptimizing > li > span {
  /* border: solid; */
  color: var(--customColor);
}
.exploreSeoOptimizing > li > a {
  text-decoration: none;
  color: var(--customColor);
}
.exploreSeoOptimizing {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 30px;
}
.exploreSeoOptimizing > h5 {
  font-size: 25px;
  color: var(--customColor);
  text-align: center;
  width: 100%;
}
.exploreSeoOptimizing > p {
  font-size: 20px;
  width: 100%;
  text-align: justify;
}
.exploreSeoOptimizing > p > a {
  color: var(--customColor);
  text-decoration: none;
}
.exploreSeoOptimizing > li > ul > li > p > a {
  text-decoration: none;
  color: var(--customColor);
}
.exploreSeoOptimizing > li > ul > li {
  list-style: none;
  font-size: 20px;
  text-align: justify;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 90%;
}
.exploreSeoOptimizing > li > ul > li > span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--customColor);
  text-align: start;
}
.exploreSeoOptimizing > li > ul > li > span > img {
  width: 20px;
  height: 20px;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .exploreSeoOptimizing > h2 {
    color: var(--customColor);
    width: 100%;
    text-align: center;
    font-size: 20px;
  }
  .exploreSeoOptimizing > h3 {
    color: var(--customColor);
    width: 100%;
    text-align: start;
    font-size: 20px;
  }
  .exploreSeoOptimizing {
    /* border: solid red; */
    padding: 5px;
  }
}
