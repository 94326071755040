.lp1Testimonial {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
}
.lp1TestimonialHead {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.lp1TestimonialHead > h6 {
  color: var(--customColor);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: start;
  width: 100%;
}
.lp1TestimonialHead > h6::before {
  content: "•";
  font-size: 20px;
  color: var(--customColor);
}
.lp1TestimonialHead > h6::after {
  content: "•";
  font-size: 20px;
  color: rgb(0, 174, 255);
}
.lp1TestimonialHead > h2 {
  font-size: 40px;
}
.lp1TestimonialMain {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}
.lp1TestimonialCard {
  /* border: solid; */
  width: 600px;
  height: 400px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.lp1TestimonialCard > img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.lp1TestimonialCardCover {
  /* border: solid green; */
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 80%;
  height: 100%;
  background: linear-gradient(
    to left,
    #fd8643,
    #fd8743bc,
    #fd874376,
    #fd874300
  );
  padding: 20px;
  transform: translateX(100%);
  transition: 300ms ease-in-out;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.lp1TestimonialCardCover-Content > h3 {
  color: white;
  font-size: 30px;
}
.lp1TestimonialCardCover-Content > p {
  text-align: justify;
  font-size: 15px;
  color: white;
}
.lp1TestimonialCard:hover .lp1TestimonialCardCover {
  transform: translateX(0%);
}
.lp1TestimonialCardCover-Content {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.lp1TestimonialCardCover-Content > span {
  width: 100%;
  color: var(--customGradient);
}
.lp1TestimonialCardCover-Content > span > h5 {
  font-weight: bold;
}
.lp1TestimonialCardCover-Content > span > p {
  font-weight: bold;
  color: white;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .lp1TestimonialCard {
    /* border: solid; */
    width: 100%;
    height: 500px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
  }
  .lp1TestimonialCardCover {
    /* border: solid green; */
    width: 100%;
    height: 100%;
    padding: 10px;
    transform: translateX(0%);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fd874396;

  }
  .lp1TestimonialCard > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .lp1TestimonialCardCover-Content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .lp1TestimonialHead > h2 {
    font-size: 40px;
    text-align: center;
  }
  .lp1TestimonialCardCover-Content > p {
    text-align: justify;
    font-size: 18px;
    color: white;
  }
}
