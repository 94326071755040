.popup {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.512);
  overflow-y:scroll;
  display: none;
  justify-content: center;
  align-items: center;
}
.popUpContainer {
  border: 1px solid rgba(255, 255, 255, 0.274);
  height: 75%;
  width: 50%;
  /* background:var(--customGradient) ; */
  border-radius: 20px;
  padding: 10px;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.567);
}
.popUpContent {
  /* border: solid; */
  width: 100%;
  position: relative;
  height: 50px;
}
.popUpContent:nth-child(2) {
  /* border: solid green; */
  height: 90%;
}
.popupIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  right: 0;
  padding: 10px;
  color: var(--customColor);
  background-color: var(--customColor2);
  border-radius: 100%;
}
.popUpForm {
  /* border: solid blue; */
  height: 100%;
}
.privacy {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  color: black;
  font-size: 15px;
  text-align: justify;
  padding: 10px;
}
.privacy > input {
  margin-top: 8px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .popUpContainer {
    height: 90%;
    width: 400px;
    border-radius: 10px;
    padding: 0px;
    transform: scale(0.9);
    margin-bottom: 20px;
  }
  .popUpContent {
    /* border: solid; */
    width: 100%;
    position: relative;
    height: 50px;
  }
  .popupIcon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 10px;
    color: var(--customColor);
    background-color: var(--customColor2);
    border-radius: 100%;
  }
  .privacy {
    font-size: 12px;
  }
}
