.lp1Why {
  /* border: solid red; */
  width: 100%;
  height: 200px;
  background-color: var(--customColor);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.lp1WhyContent {
  /* border: solid green; */
  height: 100%;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
}
.lp1WhyContent > h2 {
  font-size: 40px;
  transform: scale(1.2);
  opacity: 0.3;
  color: white;
}
.lpWhyContentCover {
  position: absolute;
  top: 0;
  left: 0;
  /* border: solid; */
  height: 75%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.lpWhyContentCover > h3 {
  color: white;
  letter-spacing: 2px;
  font-size: 18px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .lp1Why {
    /* border: solid red; */
    width: 100%;
    height: 100%;
  }
  .lp1WhyContent {
    /* border: solid green; */
    height: 200px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: relative;
  }
  .lp1Why {
    flex-direction: column;
  }
}
