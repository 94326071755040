.websiteClient {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  background-color: white;
  padding: 20px;
}
.websiteClientHead > h6 {
  color: var(--customColor);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
  width: 100%;
}
.websiteClientHead > h6::before {
  content: "•";
  font-size: 20px;
  color: var(--customColor);
}
.websiteClientHead > h6::after {
  content: "•";
  font-size: 20px;
  color: rgb(0, 174, 255);
}
.websiteClientHead > h2 {
  font-size: 50px;
  text-align: center;
}
.websiteClientHead > h2 > span {
  color: var(--customColor);
}
/* ---------------------WebsiteClientMain----------------------------- */
.websiteClientMain {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
}
.websiteClientMainContent {
  /* border: solid blue; */
  width: 70%;
  height: 300px;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  flex-direction: row;
  padding: 20px;
}
.websiteClientMainContent > div {
  width: 100%;
}
.item {
  /* border: solid green; */
  width: 150px;
  height: 150px;
  overflow: hidden;
  margin-left: 20%;
}
.item > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .websiteClientHead > h2 {
    font-size: 40px;
  }
  .websiteClientMainContent {
    /* border: solid blue; */
    width: 100%;
  }
  .item {
    /* border: solid green; */
    margin-left: 25%;
  }
}
