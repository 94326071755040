.blogMain {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
}
.blogCard {
  /* border: solid blue; */
  width: 400px;
  /* height: 450px; */
  margin: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 0 0 5px grey;
}

.blogImg {
  /* border: solid brown; */
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}
.blogImg > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 500ms ease-in-out;
}
.blogCard:hover .blogImg > img {
  transform: scale(1.1);
}
.blogCard > h4 {
  /* border: solid green; */
  width: 100%;
  text-align: start;
  padding: 10px;
  transition: 500ms ease-in-out;
}
.blogCard:hover .blogCardH4 {
  color: var(--customColor);
}
.blogCard > p {
  font-size: 18px;
  padding: 10px;
  color: grey;
}
.blogBtn {
  width: 100%;
  padding: 10px;
}
.blogBtn > a {
  text-decoration: none;
}
.blogBtn > a > button {
  border: none;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: var(--customColor);
  color: white;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.blogBtn > a > button::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 0;
  background: radial-gradient(
    465px at -15.1% -25%,
    rgb(11, 18, 236) 0%,
    rgb(67, 166, 238) 49%,
    rgb(126, 203, 244) 90.2%
  );
  transition: 300ms ease-in-out;
  z-index: -1;
}
.blogBtn > a > button:hover::after {
  width: 100%;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
    .blogCard {
        /* border: solid blue; */
       min-width: 300px;
        margin: 40px;
   
      }
}
