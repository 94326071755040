.websiteBenefits {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  width: 100%;
  padding: 30px;
}
.websiteBenefitsHeader > h6 {
  color: var(--customColor);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
  width: 100%;
}
.websiteBenefitsHeader > h6::before {
  content: "•";
  font-size: 20px;
  color: var(--customColor);
}
.websiteBenefitsHeader > h6::after {
  content: "•";
  font-size: 20px;
  color: rgb(0, 174, 255);
}
.websiteBenefitsHeader > h2 {
  font-size: 50px;
  text-align: center;
}
.websiteBenefitsHeader > p {
  font-size: 20px;
  text-align: center;
  color: grey;
}
/* ------------------WebsiteBenefitsCard---------------- */
.websiteBenefitsCard {
  /* border: solid red; */
  width: 90%;
  background-color: white;
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  position: relative;
}
.websiteBenefitsCard2 {
  /* border: solid red; */
  width: 90%;
  background-color: white;
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row-reverse;
  gap: 30px;
  position: relative;
}
.websiteBenefitsCard-Content1 {
  /* border: solid blue; */
  width: 50%;
  height: 420px;
  overflow: hidden;
  border-radius: 20px;
}
.websiteBenefitsCard-Content1 > img {
  width: 100%;
  height: 420px;
  object-fit: cover;
}
.websiteBenefitsCard-Content2 {
  /* border: solid black; */
  width: 40%;
  height: 100%;
}
.websiteBenefitsCard-Content2 {
  /* border: solid rebeccapurple; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
}
.websiteBenefitsCard-Content2 > h3 {
  font-size: 30px;
  font-weight: bolder;
}
.websiteBenefitsCard-Content2Img {
  /* border: solid red; */
  border-radius: 100%;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--customColor);
}
.benefitsIcon {
  /* border: solid blue; */
  padding: 15px;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  color: white;
}
.websiteBenefitsCard-Content2 > p {
  font-size: 18px;
  color: grey;
  text-align: justify;
}
.websiteBenefitsCard-Content2 > button {
  border: none;
  background-color: var(--customBlue);
  color: white;
  padding: 10px;
  border-radius: 30px;
  transition: 300ms ease-in-out;
}
.appointStepsCardContent2 > button:hover {
  background-color: var(--customDarkBlue);
}
.websiteBenefitsCard1-Content3 {
  /* border: solid; */
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
}
.websiteBenefitsCard1-Content3 > h2 {
  font-size: 100px;
  color: grey;
  opacity: 0.2;
  font-weight: bolder;
}
.websiteBenefitsCard2-Content3 {
  /* border: solid; */
  position: absolute;
  top: 0;
  left: 500px;
  padding: 20px;
}
.websiteBenefitsCard2-Content3 > h2 {
  font-size: 100px;
  color: grey;
  opacity: 0.2;
  font-weight: bolder;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .websiteBenefitsHeader > h2 {
    font-size: 40px;
    text-align: center;
  }
  .websiteBenefitsCard {
    /* border: solid red; */
    flex-direction: column;
  }
  .websiteBenefitsCard-Content1 {
    /* border: solid blue; */
    width: 100%;
    height: 420px;
    overflow: hidden;
    border-radius: 20px;
  }
  .websiteBenefitsCard-Content2 {
    /* border: solid black; */
    width: 100%;
    height: 100%;
  }
  .websiteBenefitsCard2 {
    /* border: solid red; */
    flex-direction: column;
  }
  .websiteBenefitsCard2-Content3 {
    /* border: solid; */
    top: 420px;
    left: 170px;
    padding: 0;
  }
  .websiteBenefitsCard1-Content3 {
    /* border: solid; */
    top: 420px;
    left: 170px;
    padding: 0;
  }
}
@media screen and (min-width: 769px) and (max-width: 990px) {
  .websiteBenefitsHeader > h2 {
    font-size: 40px;
  }
  .websiteBenefitsCard {
    /* border: solid red; */
    flex-direction: column;
  }
  .websiteBenefitsCard-Content1 {
    /* border: solid blue; */
    width: 100%;
    height: 420px;
    overflow: hidden;
    border-radius: 20px;
  }
  .websiteBenefitsCard-Content2 {
    /* border: solid black; */
    width: 100%;
    height: 100%;
  }
  .websiteBenefitsCard2 {
    /* border: solid red; */
    flex-direction: column;
  }
  .websiteBenefitsCard2-Content3 {
    /* border: solid; */
    top: 420px;
    left: 170px;
    padding: 0;
  }
  .websiteBenefitsCard1-Content3 {
    /* border: solid; */
    top: 420px;
    left: 170px;
    padding: 0;
  }
}
