.lp1Industry {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.lp1IndustryContent1 {
  /* border: solid; */
  width: 40%;
  height: 600px;
  background-color: var(--customColor);
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.lp1IndustryContent1 > img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  top: 0;
  left: 0;
  object-position: 0px;
}
.lp1IndustryContent2 {
  /* border: solid; */
  width: 60%;
  height: 600px;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.lp1IndustryContent2 > h6 {
  color: var(--customColor);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  text-align: start;
  width: 100%;
}
.lp1IndustryContent2 > h6::before {
  content: "•";
  font-size: 20px;
  color: var(--customColor);
}
.lp1IndustryContent2 > h6::after {
  content: "•";
  font-size: 20px;
  color: rgb(0, 174, 255);
}
.lp1IndustryContent2 > h2 {
  font-size: 50px;
  width: 80%;
}
.lp1IndustryCover {
  /* border: solid green; */
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
}
.lp1IndustryCard {
  /* border: solid brown; */
  width: 300px;
  height: 350px;
  border-radius: 5px;
  margin-bottom: 40px;
  overflow: hidden;
  position: relative;
}
.lp1IndustryCard > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.lp1IndustryCardCover {
  width: 100%;
  height: 100%;
  /* border: solid yellow; */
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  transition: 300ms ease-in-out;
}
.lp1IndustryCardCover:hover {
  background-color: rgba(243, 243, 243, 0.581);
}
.lp1IndustryCardCover-Content {
  width: 100%;
  height: 100%;
  padding: 10px;
  transform: translateY(300px);
  background: var(--customGradient);
  transition: 300ms ease-in-out;
}
.lp1IndustryCardCover-Content > h4 {
  color: white;
}
.lp1IndustryCardCover-Content > p {
  font-size: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  font-weight: 500;
  text-align: justify;
}
.lp1IndustryCardCover-Content > p > small {
  font-weight: bold;
  color: rgb(91, 91, 91);
}
.lp1IndustryCardCover:hover .lp1IndustryCardCover-Content {
  transform: translateY(100px);
}
.lp1Menu {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 70%;
  height: 100%;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .lp1Industry {
    /* border: solid red; */
    flex-direction: column;
    margin-top: 50px;
  }
  .lp1IndustryContent1 {
    /* border: solid; */
    width: 100%;
    height: 100%;
    background-color: var(--customColor);
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .lp1IndustryContent2 {
    /* border: solid; */
    width: 100%;
    height: 100%;
    padding: 10px;
  }
  .lp1IndustryCover {
    /* border: solid green; */
    width: 100%;
    height: 100%;
    position: static;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .lp1IndustryContent2 > h6 {
    color: var(--customColor);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-align: center;
    width: 100%;
  }
  .lp1IndustryContent2 > h2 {
    font-size: 40px;
    width: 100%;
    text-align: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 990px) {
  .lp1Industry {
    /* border: solid red; */
    flex-direction: column;
  }
  .lp1IndustryContent1 {
    /* border: solid; */
    width: 100%;
    height: 100%;
    background-color: var(--customColor);
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .lp1IndustryContent2 {
    /* border: solid; */
    width: 100%;
    height: 100%;
    padding: 10px;
  }
  .lp1IndustryCover {
    /* border: solid green; */
    width: 100%;
    height: 100%;
    position: static;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .lp1IndustryContent2 > h6 {
    color: var(--customColor);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-align: center;
    width: 100%;
  }
  .lp1IndustryContent2 > h2 {
    font-size: 40px;
    width: 100%;
    text-align: center;
  }
}
